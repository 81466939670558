.symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
}

.symbol .symbol-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    color: #3F4254;
    background-color: #F3F6F9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
}

.symbol-35 .symbol-label {
    width: 35px;
    height: 35px;
}
.symbol-light-danger .symbol-label {
    background-color: #FFE2E5;
    color: #F64E60;
}
.symbol-primary .symbol-label {
    background-color: #3699FF;
    color: #FFFFFF;
}


/* spinner */
.spinner {
    position: relative;
}
.spinner.spinner-track.spinner-primary::before {
    border: 2px solid #cfe7ff;
    border-top-color: rgb(207, 231, 255);
    border-top-color: #2791ff;
}
.spinner.spinner-track::before {
    animation: animation-spinner .5s linear infinite;
}
.spinner.spinner-primary::before {
    border: 2px solid #3699FF;
    border-right: 2px solid transparent;
}
.spinner.spinner-sm::before {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.625rem;
}
.spinner::before {
    animation: animation-spinner .5s linear infinite;
}
.spinner::before {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.75rem;
}
.spinner::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    border: 2px solid #D1D3E0;
    border-right: 2px solid transparent;
}

@keyframes animation-spinner {
    to {
        transform: rotate(360deg);
    }
}