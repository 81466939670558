//
// Main Stylesheet
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Include global config
@import "config";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

// 2: Include demo config (that overrides the above global config)
@import "global/layout/config";

// 3: Bootstrap Include
@import "global/integration/frameworks/bootstrap/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "global/integration/frameworks/bootstrap/bootstrap";

// 3: Components include
@import "global/include";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

@import "custom/boo";

// 5: Demo Configs
@import "global/layout/header/config";
@import "global/layout/aside/config";
@import "global/layout/footer/config";


// 6: Demo Includes
// Layout
@import "global/layout/base/base";

@import "global/layout/header/header";
@import "global/layout/header/base-mobile";
@import "global/layout/header/menu";
@import "global/layout/header/topbar";
@import "global/layout/header/brand";

@import "global/layout/aside/aside";
@import "global/layout/aside/menu";

@import "global/layout/content/content";

@import "global/layout/footer/footer";
